/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
#header {
  transform: translate3d(0, 0, 0);
  --bs-navbar-padding-y: 0.75rem;
  background: #202427 !important;
  //   background: linear-gradient(
  //     180deg,
  //     rgb(var(--bs-primary-rgb)) 0%,
  //     rgba(var(--bs-primary-rgb), 0.95) 100%
  //   );
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15),
    0 0.125rem 0.25rem rgb(0 0 0 / 8%);

  .logo {
    max-height: 2rem;
  }

  .nav-link {
    color: #fff;
    &.icon-link {
      width: 36px;
      height: 36px;
    }
  }

  .answer-navBar {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    border: none;
  }
  .answer-navBar:focus {
    box-shadow: none;
  }

  .lg-none {
    display: none !important;
  }

  .hr {
    color: var(--bs-navbar-color);
  }

  .placeholder-search {
    border-radius: 38px;
    padding-left: 20px;
    background-color: #e7e8e933 !important;
    border: none;
    color: #fff;

    &:focus {
      border: $border-width $border-style $border-color;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  // style for colored navbar
  &.theme-colored {
    .placeholder-search {
      border-radius: 38px;
      padding-left: 20px;
      box-shadow: none;
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
      border: $border-width $border-style rgba(255, 255, 255, 0.2);
      &:focus {
        border: $border-width $border-style $border-color;
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }

  // style for colored navbar
  &.theme-light {
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255) 0%,
      rgba(255, 255, 255, 0.95) 100%
    );
  }

  .maxw-400 {
    max-width: 400px;
  }

  .minw-200 {
    min-width: 200px;
  }

  .me-2 {
    min-width: 60px;
    color: #fff;
  }

  .ee-header {
    max-width: 98%;
    .ee-menu {
      display: flex;
      margin-left: 20px;
      height: 40px;
      align-items: center;
      min-width: 448px;

      .nav-link {
        height: 64px;
        line-height: 64px;
        padding: 0 20px;
      }

      .ee-active.nav-link {
        background-color: #0099ff;
      }
    }

    .ee-search {
      flex: 1;

      form {
        max-width: 400px;
        padding-right: 20px;
      }
    }
  }

  .ee-login {
    color: white !important;
  }
}

.ee-m-nav {
  width: 280px !important;
  background: #202427;
  color: white !important;
  border-left: 0 !important;

  .offcanvas-header,
  .offcanvas-body {
    background: #202427;
  }

  .offcanvas-body {
    padding: 0;
  }

  .btn-close {
    opacity: 1;
  }

  .ee-search {
    padding: 16px 28px;

    .form-control {
      border-radius: 19px;
      background-color: #202427;
      border-color: #ffffff1a;
    }

    input::placeholder {
      color: #ffffff99;
    }
  }

  .ee-menu {
    display: flex;
    align-items: center;
    margin: 0 0 1rem 0;
    flex-direction: column;
    min-width: unset;

    a {
      padding: 10px 28px;
      width: 100%;
      text-align: left;
      color: white;
    }

    .ee-active.nav-link {
      background-color: #0099ff;
    }
  }

  .ee-line {
    width: 100%;
    padding: 14px 28px;

    span {
      display: block;
      height: 1px;
      width: 100%;
      background-color: #ffffff1a;
    }
  }

  .ee-login {
    color: white;
    width: 100%;
    padding-left: 28px;
    text-align: left;
  }
}

@media (max-width: 991.9px) {
  #header {
    .nav-grow {
      flex-grow: 1 !important;
    }

    .lg-none {
      display: flex !important;
    }

    .ee-header {
      max-width: 98%;

      .ee-search {
        form {
          max-width: unset;
          padding-right: 0;
        }
      }
    }
  }

  .ee-down {
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .ee-mini-nav {
    width: 100%;
    box-shadow: 0px -20px 14px 12px;
    a {
      flex: 1;
      text-align: center;
      border-radius: 0 !important;
      color: #202427;
    }
  }
}
